<template>
  <el-card class="serach-area">
    <el-input
      v-model="cardId"
      placeholder="输入卡密进行查询"
      @keyup.enter="handleSearchCard"
    >
      <template #append>
        <el-button @click="handleSearchCard" :icon="Search" />
      </template>
    </el-input>
  </el-card>

  <div class="info" v-if="show">
    <el-descriptions :column="2" border>
      <el-descriptions-item label="名称">
        {{ cardInfo.goods ? cardInfo.goods.title : '' }}
      </el-descriptions-item>

      <el-descriptions-item label="类型">
        {{ cardInfo.model }}
      </el-descriptions-item>

      <el-descriptions-item label="卡密" :span="2">
        {{ cardInfo.cardId }}
      </el-descriptions-item>

      <el-descriptions-item label="卡密价值">{{
        cardInfo.price
      }}</el-descriptions-item>
      <el-descriptions-item label="卡密生成者">{{
        cardInfo.generateUser
      }}</el-descriptions-item>
      <el-descriptions-item label="是否使用">{{
        cardInfo.used === 1 ? '已使用' : '未使用'
      }}</el-descriptions-item>
      <el-descriptions-item label="卡密使用者">{{
        cardInfo.user ? cardInfo.user : '暂未使用'
      }}</el-descriptions-item>

      <el-descriptions-item label="创建时间" :span="2">
        <span>
          {{ $filters.dateFilter(cardInfo.genTime, 'YYYY-MM-DD HH:mm:ss') }}
        </span>
      </el-descriptions-item>

      <el-descriptions-item label="使用时间" :span="2">
        <div v-if="cardInfo.useTime">
          {{ $filters.dateFilter(cardInfo.useTime, 'YYYY-MM-DD HH:mm:ss') }}
        </div>
        <div v-else>暂未使用</div>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { getCardDetail } from '@/api/kami'

const cardId = ref('')
const cardInfo = ref({})

const show = computed(() => JSON.stringify(cardInfo.value) !== '{}')

const handleSearchCard = async () => {
  try {
    const result = await getCardDetail({
      cardId: cardId.value
    })
    cardInfo.value = result
  } catch (error) {}
}
</script>

<style lang="scss" scoped>
.serach-area {
  margin-bottom: 32px;
}
</style>

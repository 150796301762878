<template>
  <div class="kami-list">
    <el-card class="serach-area">
      <GenerateKami @genKamiOver="getListData" />

      <div class="mt" v-if="showAdminOperation">
        <el-input
          v-model="mobile"
          placeholder="输入手机号可以进行模糊查询"
          @keyup.enter="getListData"
        >
          <template #append>
            <el-button @click="getListData" :icon="Search" />
          </template>
        </el-input>
      </div>
    </el-card>

    <el-card>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column label="#" type="index" />
        <el-table-column label="卡密类型">
          <template #default="{ row }">
            {{ row.goods ? row.goods.title : ' ' }}
          </template>
        </el-table-column>
        <el-table-column prop="cardId" label="卡密"> </el-table-column>
        <el-table-column label="是否使用" align="center">
          <template v-slot="{ row }">
            {{ row.used === 1 ? '已使用' : '未使用' }}
          </template>
        </el-table-column>

        <el-table-column label="创建时间">
          <template #default="{ row }">
            {{ $filters.dateFilter(row.genTime, 'YYYY-MM-DD HH:mm:ss') }}
          </template>
        </el-table-column>

        <el-table-column label="使用时间">
          <template #default="{ row }">
            <div v-if="row.useTime">
              {{ $filters.dateFilter(row.useTime, 'YYYY-MM-DD HH:mm:ss') }}
            </div>
            <div v-else>暂未使用</div>
          </template>
        </el-table-column>

        <el-table-column label="使用人">
          <template #default="{ row }">
            <div v-if="row.user">
              {{ row.user }}
            </div>
            <div v-else>暂未使用</div>
          </template>
        </el-table-column>
        <el-table-column prop="generateUser" label="创建人"> </el-table-column>

        <!-- 操作 -->
        <el-table-column
          :label="$t('msg.excel.action')"
          fixed="right"
          width="160"
        >
          <template #default="{ row }">
            <el-button type="primary" size="mini" @click="onShowClick(row)"
              >使用</el-button
            >
            <el-button type="danger" size="mini" @click="onDestoryClick(row)"
              >销毁</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[2, 5, 10, 20]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <UseKami v-model="showUseKamiModal" :kami="kamiInfo" />
  </div>
</template>

<script setup>
import { ref, onActivated, computed } from 'vue'
import { findCardList, freezeCard } from '@/api/kami'
import { watchSwitchLang } from '@/utils/i18n'
import { ElMessageBox, ElMessage } from 'element-plus'
import GenerateKami from './components/GenerateKami.vue'
import UseKami from './components/UseKami.vue'
import { useStore } from 'vuex'
import { Search } from '@element-plus/icons-vue'

// 生成卡密的弹窗

// 数据相关
const tableData = ref([])
const total = ref(0)
const page = ref(1)
const size = ref(10)
const mobile = ref('')

const store = useStore()

const showAdminOperation = computed(() =>
  // [6, 7, 8, 9] 表示为 角色id
  [6, 7].includes(store.getters.maxlevel.id)
)

// 管理员默认查询所有卡密，其他成员只能查看自己的
const _mobile = computed(() => {
  if (showAdminOperation.value) {
    return mobile.value
  } else {
    return store.getters.userInfo.mobile
  }
})

// 获取数据的方法
const getListData = async () => {
  const result = await findCardList({
    page: page.value,
    size: size.value,
    mobile: _mobile.value
  })
  tableData.value = result.list
  total.value = result.total
}
getListData()
// 监听语言切换
watchSwitchLang(getListData)

// 分页相关
/**
 * size 改变触发
 */
const handleSizeChange = (currentSize) => {
  size.value = currentSize
  getListData()
}

/**
 * 页码改变触发
 */
const handleCurrentChange = (currentPage) => {
  page.value = currentPage
  getListData()
}

// 处理导入用户后数据不重新加载的问题
onActivated(getListData)

/**
 * 删除按钮点击事件
 */
const onDestoryClick = (row) => {
  ElMessageBox.confirm(`确定要删除卡密${row.cardId}吗?`, {
    type: 'warning'
  }).then(async () => {
    freezeCard({
      cardId: row.cardId
    })
      .then((result) => {
        ElMessage.success('操作成功')
        // 重新渲染数据
        getListData()
      })
      .catch((err) => console.log('onDestoryClick', err))
  })
}

/**
 * 使用按钮点击事件
 */
const showUseKamiModal = ref(false)
const kamiInfo = ref({})
const onShowClick = (row) => {
  kamiInfo.value = row
  showUseKamiModal.value = true
}
</script>

<style lang="scss" scoped>
.kami-list {
  .serach-area {
    margin-bottom: 12px;
  }

  .header {
    margin-bottom: 22px;
    text-align: right;
  }
  ::v-deep .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .pagination {
    margin-top: 20px;
    text-align: center;
  }

  .mt {
    margin-top: 24px;
  }
}
</style>

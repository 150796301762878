<template>
  <el-button type="primary" @click="generateKami">生成卡密</el-button>

  <!-- 生成卡密弹窗 -->
  <el-dialog title="生成卡密" :model-value="show" @close="closed">
    <div class="discount">
      <span>尊敬的</span>
      <el-tag>{{ roleName }}</el-tag>
      <span>，您当前的折扣为：</span>
      <span class="num">{{ discount }}折</span>
    </div>
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      class="demo-ruleForm"
      status-icon
    >
      <el-form-item label="当前余额">
        <span>{{ $store.getters.userInfo.remainingSum }}</span>
      </el-form-item>
      <!-- 卡密的种类 -->
      <el-form-item label="卡密类型" prop="type">
        <el-select v-model="ruleForm.type" placeholder="请选择卡密类型">
          <el-option
            v-for="(goods, index) in goodsList"
            :key="index"
            :label="goods.title + '-' + goods.price + '元'"
            :value="goods.productId"
          />
        </el-select>
      </el-form-item>
      <!-- 生成卡密的数量 -->
      <el-form-item label="卡密数量" prop="num">
        <el-input
          v-model="ruleForm.num"
          type="number"
          placeholder="请输入卡密数量"
        />
      </el-form-item>

      <!-- 生产所需要的金额 -->
      <el-form-item label="原价">
        <span>{{ money }}</span>
      </el-form-item>

      <el-form-item label="折后价">
        <span>{{ discountMoney }}</span>
      </el-form-item>

      <!-- 生成的卡密列表 -->
      <el-input
        style="margin-bottom: 24px"
        v-if="kamiList.length"
        v-model="kamiList"
        type="textarea"
      />
      <!-- 生成 -->
      <el-form-item>
        <el-button type="primary" @click="submitForm(ruleFormRef)">
          生成
        </el-button>
        <el-button v-if="kamiList.length" @click="closed">关闭</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script setup>
import { defineEmits, ref, watch, reactive, computed } from 'vue'
import { findAllGoods } from '@/api/goods'
import { generateCard } from '@/api/kami'
import { useStore } from 'vuex'

const emits = defineEmits(['genKamiOver'])
const store = useStore()

const discount = computed(() => store.getters.maxlevel?.discount)
const roleName = computed(() => store.getters.maxlevel?.title)

/**
 * 获取商品信息
 */
const goodsList = ref([])
const getGoodsData = async () => {
  const result = await findAllGoods()
  goodsList.value = result
}
getGoodsData()

/**
 * 生成卡密
 */
const kamiList = ref([])
const handleGenerateCard = async () => {
  try {
    const result = await generateCard({
      productId: ruleForm.type,
      num: ruleForm.num
    })
    kamiList.value = result
    store.dispatch('user/getUserInfo')
    emits('genKamiOver')
    // closed()
  } catch (error) {}
}

/**
 * 表单相关
 */
const ruleFormRef = ref()
const ruleForm = reactive({
  type: '',
  num: undefined
})

const findPrice = (productId) => {
  const goods =
    goodsList.value?.find((item) => item.productId === productId) ?? {}
  return goods.price
}

const money = computed(() => {
  if (ruleForm.num) {
    return findPrice(ruleForm.type) * ruleForm.num
  }
  return 0
})

const discountMoney = computed(() => {
  // 控制最低金额为 0.01 元
  if (money.value <= 0.01) {
    return money.value.toFixed(2)
  }
  // 算一下折扣价格
  let price = money.value * discount.value
  if (typeof price !== 'number') {
    price = Number(price)
  }
  // 保留2位小数
  return price.toFixed(2)
})

const rules = reactive({
  num: [{ required: true, message: '请输入卡密数量', trigger: 'blur' }],
  type: [
    {
      required: true,
      message: '请选择卡密类型',
      trigger: 'change'
    }
  ]
})

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      // console.log('submit!', ruleForm)
      handleGenerateCard()
    } else {
      console.log('error submit!', fields)
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
}

/**
 * 弹窗
 */
const show = ref(false)
function generateKami() {
  show.value = true
}

// 保证每次打开重新获取用户角色数据
watch(show, (val) => {
  resetForm(ruleFormRef.value)
  kamiList.value = []
})

/**
 * 关闭
 */
const closed = () => {
  show.value = false
}
</script>

<style lang="scss" scoped>
.discount {
  display: flex;
  align-items: center;
  margin-left: 52px;
  margin-top: -4px;
  margin-bottom: 24px;

  .num {
    font-size: 24px;
    color: #ff483c;
    font-weight: bold;
  }
}
</style>

import request from '@/utils/request'

/**
 * 生成卡密
 */
export const generateCard = data => {
  return request({
    url: '/v1/back/generateCard',
    method: 'POST',
    data
  })
}

/**
 * 卡密兑换
 */
export const handleCarmiExchange = data => {
  return request({
    url: '/v1/handleCarmiExchange',
    method: 'POST',
    data
  })
}

/**
 * 通过卡密id查询卡密详情
 */
export const getCardDetail = (params) => {
  return request({
    url: '/v1/back/getCardDetail',
    params
  })
}

/**
 * 查询我创建的卡密
 */
export const findCardList = (params) => {
  return request({
    url: '/v1/back/findCardList',
    params
  })
}

/**
 * 冻结卡密
 */
export const freezeCard = (params) => {
  return request({
    url: '/v1/back/freezeCard',
    params
  })
}

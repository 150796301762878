import request from '@/utils/request'

/**
 * 获取所有商品
 */
export const findAllGoods = () => {
  return request({
    url: '/v1/findGoods'
  })
}

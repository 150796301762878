<template>
  <el-dialog title="操作" :model-value="modelValue" @close="closed">
    <!-- 当前使用的卡密 -->
    <el-input class="mb" v-model="cardId" disabled />
    <!-- 使用人的手机号 -->
    <el-input class="mb" v-model="mobile" placeholder="请填写使用人的手机号" />

    <el-button @click="handleUseKami"> 使用 </el-button>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue'
import { handleCarmiExchange } from '@/api/kami'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  kami: {
    type: Object,
    required: true
  }
})

const cardId = computed(() => {
  return props.kami.cardId
})

const emits = defineEmits(['update:modelValue', 'updateList'])

const store = useStore()

/**
 * 使用卡密
 */
const mobile = ref('')
const handleUseKami = async () => {
  if (!mobile.value) {
    ElMessage.error('请输入手机号')
    return
  }
  try {
    await handleCarmiExchange({
      cardId: props.kami.cardId,
      mobile: mobile.value
    })

    ElMessage.success('充值成功')

    // 通知卡密列表进行刷新
    updateListData()

    // 如果是在给自己充值，就刷新一下自己的信息
    if (store.getters.userInfo.mobile === mobile.value) {
      store.dispatch('user/getUserInfo')
    }

    closed()
  } catch (error) {}
}

/**
 * 关闭
 */
const closed = () => {
  emits('update:modelValue', false)
}

const updateListData = () => {
  closed()
  emits('updateList')
}
</script>

<style lang="scss" scoped>
.mb {
  margin-bottom: 24px;
}
</style>
